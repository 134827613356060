const nav = document.querySelector('nav') as HTMLDivElement;
const socials = document.querySelector('#socials') as HTMLDivElement;
const ca = document.querySelector('#ca') as HTMLDivElement;

window.addEventListener('scroll', () => {
	if (window.scrollY > 0) {
		nav.classList.add('scrolled');
		socials.classList.add('scrolled');
		ca.classList.add('scrolled');
	} else {
		nav.classList.remove('scrolled');
		socials.classList.remove('scrolled');
		ca.classList.remove('scrolled');
	}
});

const caText = document.querySelector('#ca') as HTMLDivElement;
caText.addEventListener('click', () => {
	navigator.clipboard.writeText('2bZzs6UZGWqr1rUzfe6GcUoA4MmrzQrYJsYRLWttNJhS');
	caText.innerText = 'CA: Copied!';
	setTimeout(() => {
		caText.innerText = 'CA: 2bZzs6UZGWqr1rUzfe6GcUoA4MmrzQrYJsYRLWttNJhS';
	}, 1000);
});
